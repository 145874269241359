// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.input-field {
  border: 1px solid #312d2d;
  /* transition: border-color 0.3s ease-in-out; */
}

.input-field:focus {

  border-color: transparent; /* Set border color to transparent */
}
.input-field:hover{
  border-color: transparent; /* Set border color to transparent */

}

.form {
  display: flex;
  flex-direction: column;
  /* font-family: 'Times New Roman', Times, serif; */
  font-family: 'Roboto', sans-serif;
  
}

.container {
  font-family: 'Roboto', sans-serif;
  width: 90%;
  max-width: 400px; /* Set maximum width */
  height: auto;
  margin: auto; /* Center the container horizontally */
  border-radius: 15px;
  padding: 20px;
  background-color: #f8f9fa; /* Set background color */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

@media (max-width: 576px) {
  .container {
    width: 90%;
    margin: auto;
  }
}


.signup{
  font-size: 1.75rem; 
  font-size:larger;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;

}



`, "",{"version":3,"sources":["webpack://./src/styles/register.css"],"names":[],"mappings":";AACA;EACE,yBAAyB;EACzB,+CAA+C;AACjD;;AAEA;;EAEE,yBAAyB,EAAE,oCAAoC;AACjE;AACA;EACE,yBAAyB,EAAE,oCAAoC;;AAEjE;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kDAAkD;EAClD,iCAAiC;;AAEnC;;AAEA;EACE,iCAAiC;EACjC,UAAU;EACV,gBAAgB,EAAE,sBAAsB;EACxC,YAAY;EACZ,YAAY,EAAE,sCAAsC;EACpD,mBAAmB;EACnB,aAAa;EACb,yBAAyB,EAAE,yBAAyB;EACpD,2CAA2C,EAAE,mBAAmB;AAClE;;AAEA;EACE;IACE,UAAU;IACV,YAAY;EACd;AACF;;;AAGA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iCAAiC;EACjC,iBAAiB;;AAEnB","sourcesContent":["\n.input-field {\n  border: 1px solid #312d2d;\n  /* transition: border-color 0.3s ease-in-out; */\n}\n\n.input-field:focus {\n\n  border-color: transparent; /* Set border color to transparent */\n}\n.input-field:hover{\n  border-color: transparent; /* Set border color to transparent */\n\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  /* font-family: 'Times New Roman', Times, serif; */\n  font-family: 'Roboto', sans-serif;\n  \n}\n\n.container {\n  font-family: 'Roboto', sans-serif;\n  width: 90%;\n  max-width: 400px; /* Set maximum width */\n  height: auto;\n  margin: auto; /* Center the container horizontally */\n  border-radius: 15px;\n  padding: 20px;\n  background-color: #f8f9fa; /* Set background color */\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add box shadow */\n}\n\n@media (max-width: 576px) {\n  .container {\n    width: 90%;\n    margin: auto;\n  }\n}\n\n\n.signup{\n  font-size: 1.75rem; \n  font-size:larger;\n  font-family: 'Roboto', sans-serif;\n  font-weight: bold;\n\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
