// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .customSnackbar {
    background-color: transparent !important;
  }
  
  .customSnackbar .error{
    background-color: red !important;
  } 
  .customSnackbar .success{
    background-color: green !important;
  }  */
  /* .customSnackbar .MuiSnackbarContent-root {
    background-color: #1a7f12 ; 
    color: rgb(253, 253, 253) ; 
    width:1000px;
    
  }

.MuiSnackbarContent-action {
    justify-content: flex-start;
  }
  
  .MuiSnackbarContent-action .MuiIconButton-root {
    margin-right: auto;
  }
  
  Adjust close button width
  .MuiSnackbarContent-action .MuiIconButton-root {
    width: 32px;
    color: rgb(255, 255, 255);
  } */
  
  .customSnackbar.red {
    /* Additional styles for the red snackbar, if needed */
  }
  
  .customSnackbar.green {
    /* Additional styles for the green snackbar, if needed */
  }
  
  .MuiSnackbarContent-action {
    justify-content: flex-start;
  }
  
  .MuiSnackbarContent-action .MuiIconButton-root {
    margin-right: auto;
    width: 32px; /* Adjust close button width if desired */
    color: inherit; /* Inherit color from parent */
  }
  
    .customSnackbar .MuiSnackbarContent-root {
    /* background-color: #1a7f12 ;  */
    /* color: rgb(253, 253, 253) ;  */
    width:1000px;
    
  }`, "",{"version":3,"sources":["webpack://./src/styles/snackbar.css"],"names":[],"mappings":"AAAA;;;;;;;;;MASM;EACJ;;;;;;;;;;;;;;;;;;;KAmBG;;EAEH;IACE,sDAAsD;EACxD;;EAEA;IACE,wDAAwD;EAC1D;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,kBAAkB;IAClB,WAAW,EAAE,yCAAyC;IACtD,cAAc,EAAE,8BAA8B;EAChD;;IAEE;IACA,iCAAiC;IACjC,iCAAiC;IACjC,YAAY;;EAEd","sourcesContent":["/* .customSnackbar {\n    background-color: transparent !important;\n  }\n  \n  .customSnackbar .error{\n    background-color: red !important;\n  } \n  .customSnackbar .success{\n    background-color: green !important;\n  }  */\n  /* .customSnackbar .MuiSnackbarContent-root {\n    background-color: #1a7f12 ; \n    color: rgb(253, 253, 253) ; \n    width:1000px;\n    \n  }\n\n.MuiSnackbarContent-action {\n    justify-content: flex-start;\n  }\n  \n  .MuiSnackbarContent-action .MuiIconButton-root {\n    margin-right: auto;\n  }\n  \n  Adjust close button width\n  .MuiSnackbarContent-action .MuiIconButton-root {\n    width: 32px;\n    color: rgb(255, 255, 255);\n  } */\n  \n  .customSnackbar.red {\n    /* Additional styles for the red snackbar, if needed */\n  }\n  \n  .customSnackbar.green {\n    /* Additional styles for the green snackbar, if needed */\n  }\n  \n  .MuiSnackbarContent-action {\n    justify-content: flex-start;\n  }\n  \n  .MuiSnackbarContent-action .MuiIconButton-root {\n    margin-right: auto;\n    width: 32px; /* Adjust close button width if desired */\n    color: inherit; /* Inherit color from parent */\n  }\n  \n    .customSnackbar .MuiSnackbarContent-root {\n    /* background-color: #1a7f12 ;  */\n    /* color: rgb(253, 253, 253) ;  */\n    width:1000px;\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
