// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guide {
    max-width: 1300px;
    max-height: calc(100vh - 69px);
    height: auto;
    overflow-y: auto;
    margin-bottom: 10vh;
    margin-top: 69px;
    position: relative;
    padding: 20px;
}
.guide-button{
    background-color:#299689 !important;

}
.guide-button:hover{
    background-color:#299689 !important;
  }

  
.close-guide {
    position: absolute;
    top: 22px;
    right: 25px;
    cursor: pointer;
    font-size: 15px;
    z-index: 20;
}

.guide-content {
    max-height: 70vh;
    padding-right: 10px;
    overflow-y: auto;
}

h3 {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    padding: 5px 0;
    margin-bottom: 0;
    border-bottom: 1px solid #ccc;
}

.header-buttons {
    position: absolute;
    top: 20px;
    right: 75px;
    display: flex;
    align-items: center;
    z-index: 30; 
}

.header-buttons button {
    background-color: #299689;
    color: white;
    border: none;
    padding: 10px 10px;
    margin-left: 20px;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.header-buttons button:hover {
    background-color: #1a635a;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Guide.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,8BAA8B;IAC9B,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,mCAAmC;;AAEvC;AACA;IACI,mCAAmC;EACrC;;;AAGF;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,eAAe;IACf,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,uBAAuB;IACvB,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".guide {\n    max-width: 1300px;\n    max-height: calc(100vh - 69px);\n    height: auto;\n    overflow-y: auto;\n    margin-bottom: 10vh;\n    margin-top: 69px;\n    position: relative;\n    padding: 20px;\n}\n.guide-button{\n    background-color:#299689 !important;\n\n}\n.guide-button:hover{\n    background-color:#299689 !important;\n  }\n\n  \n.close-guide {\n    position: absolute;\n    top: 22px;\n    right: 25px;\n    cursor: pointer;\n    font-size: 15px;\n    z-index: 20;\n}\n\n.guide-content {\n    max-height: 70vh;\n    padding-right: 10px;\n    overflow-y: auto;\n}\n\nh3 {\n    position: sticky;\n    top: 0;\n    background-color: white;\n    z-index: 10;\n    padding: 5px 0;\n    margin-bottom: 0;\n    border-bottom: 1px solid #ccc;\n}\n\n.header-buttons {\n    position: absolute;\n    top: 20px;\n    right: 75px;\n    display: flex;\n    align-items: center;\n    z-index: 30; \n}\n\n.header-buttons button {\n    background-color: #299689;\n    color: white;\n    border: none;\n    padding: 10px 10px;\n    margin-left: 20px;\n    cursor: pointer;\n    outline: none;\n    border-radius: 4px;\n    transition: background-color 0.3s ease;\n}\n\n.header-buttons button:hover {\n    background-color: #1a635a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
