// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-scroll {
    overflow: auto;
    scroll-behavior: smooth; /* Enables smooth scrolling */
  }
  
  .custom-scroll::-webkit-scrollbar {
    width: 10px; /* Adjust scrollbar width if needed */
  }
  
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar thumb color */
    border-radius: 10px;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #555; /* Scrollbar thumb hover color */
  }
  
  .custom-scroll::-webkit-scrollbar-track {
    background: #f1f1f1; /* Scrollbar track color */
  }
  
  .custom-scroll {
    scroll-snap-type: y mandatory; /* Adds snap behavior for smoother scrolling */
  }
  
  .custom-scroll > * {
    scroll-snap-align: start; /* Ensures elements align properly when scrolling */
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/auditGrid.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,uBAAuB,EAAE,6BAA6B;EACxD;;EAEA;IACE,WAAW,EAAE,qCAAqC;EACpD;;EAEA;IACE,gBAAgB,EAAE,0BAA0B;IAC5C,mBAAmB;IACnB,mCAA2B;IAA3B,2BAA2B;EAC7B;;EAEA;IACE,gBAAgB,EAAE,gCAAgC;EACpD;;EAEA;IACE,mBAAmB,EAAE,0BAA0B;EACjD;;EAEA;IACE,6BAA6B,EAAE,8CAA8C;EAC/E;;EAEA;IACE,wBAAwB,EAAE,mDAAmD;EAC/E","sourcesContent":[".custom-scroll {\n    overflow: auto;\n    scroll-behavior: smooth; /* Enables smooth scrolling */\n  }\n  \n  .custom-scroll::-webkit-scrollbar {\n    width: 10px; /* Adjust scrollbar width if needed */\n  }\n  \n  .custom-scroll::-webkit-scrollbar-thumb {\n    background: #888; /* Scrollbar thumb color */\n    border-radius: 10px;\n    transition: background 0.3s;\n  }\n  \n  .custom-scroll::-webkit-scrollbar-thumb:hover {\n    background: #555; /* Scrollbar thumb hover color */\n  }\n  \n  .custom-scroll::-webkit-scrollbar-track {\n    background: #f1f1f1; /* Scrollbar track color */\n  }\n  \n  .custom-scroll {\n    scroll-snap-type: y mandatory; /* Adds snap behavior for smoother scrolling */\n  }\n  \n  .custom-scroll > * {\n    scroll-snap-align: start; /* Ensures elements align properly when scrolling */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
