// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customDropdown .dropdown-menu {
    min-width: auto; /* Resetting minimum width */
    white-space: nowrap; /* Preventing line breaks */
  }
  
  .username-container {
    color: #fff;
    margin: 0;
    padding: 0;
    margin-bottom: -3px;
  }

  .role-container {
    color: rgb(255, 255, 255);
    font-size: 11px;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .access-container{
    display: block; 
}
 
  

  .dropdown-list{
display: flex;

  }


  .dropdown{
 max-width: 200px;
  }

  .username-container{
    color: #fff;
  }
  .username-container,
  .customDropdown {
    cursor: pointer;
    display: flex;
    align-items: center;

  }

  .username {
    margin-right: 10px;
    margin-left: 10px;
    color: #007bff;
  }

  .dropdown {
    padding-left: 100px;
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
  }

.navigation{
  background: linear-gradient(to right, #274b44, #3e554f, #295047) !important;  
}
 
.toggler{
  color: white !important;
}
/* .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;..");
} */`, "",{"version":3,"sources":["webpack://./src/styles/NavBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,4BAA4B;IAC7C,mBAAmB,EAAE,2BAA2B;EAClD;;EAEA;IACE,WAAW;IACX,SAAS;IACT,UAAU;IACV,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,cAAc;AAClB;;;;EAIE;AACF,aAAa;;EAEX;;;EAGA;CACD,gBAAgB;EACf;;EAEA;IACE,WAAW;EACb;EACA;;IAEE,eAAe;IACf,aAAa;IACb,mBAAmB;;EAErB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,2BAA2B;IAC3B,eAAe;EACjB;;AAEF;EACE,2EAA2E;AAC7E;;AAEA;EACE,uBAAuB;AACzB;AACA;;GAEG","sourcesContent":[".customDropdown .dropdown-menu {\n    min-width: auto; /* Resetting minimum width */\n    white-space: nowrap; /* Preventing line breaks */\n  }\n  \n  .username-container {\n    color: #fff;\n    margin: 0;\n    padding: 0;\n    margin-bottom: -3px;\n  }\n\n  .role-container {\n    color: rgb(255, 255, 255);\n    font-size: 11px;\n    text-align: center;\n    margin: 0;\n    padding: 0;\n  }\n  \n  .access-container{\n    display: block; \n}\n \n  \n\n  .dropdown-list{\ndisplay: flex;\n\n  }\n\n\n  .dropdown{\n max-width: 200px;\n  }\n\n  .username-container{\n    color: #fff;\n  }\n  .username-container,\n  .customDropdown {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n\n  }\n\n  .username {\n    margin-right: 10px;\n    margin-left: 10px;\n    color: #007bff;\n  }\n\n  .dropdown {\n    padding-left: 100px;\n    display: flex;\n    flex-direction: row-reverse;\n    cursor: pointer;\n  }\n\n.navigation{\n  background: linear-gradient(to right, #274b44, #3e554f, #295047) !important;  \n}\n \n.toggler{\n  color: white !important;\n}\n/* .navbar-dark .navbar-toggler-icon {\n  background-image: url(\"data:image/svg+xml;..\");\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
